import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fed336",
    },
    secondary: {
      main: "#ed0010",
    },
  },
  typography: {
    fontFamily: "Open Sans",
    button: {
      textTransform: "normal",
    },
    h3: {
      fontWeight: "700",
    },
  },
});

export default theme;
