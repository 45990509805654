import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Breadcrumb from "../../components/includes/Breadcrumb";

const About = () => {
  const theme = useTheme();
  const features = [
    {
      title: "Gain de temps",
      content:
        "Trouvez rapidement la voiture parfaite en utilisant notre fonction de recherche avancée et nos filtres personnalisables. Notre application vous propose une sélection de voitures d'occasion de haute qualité, correspondant à vos critères spécifiques.",
    },
    {
      title: "Confiance",
      content:
        "Nous vérifions minutieusement chaque véhicule avant de le mettre en vente, garantissant ainsi que vous achetez une voiture d'occasion en excellent état. De plus, nous offrons un historique complet de chaque voiture, vous permettant de prendre une décision éclairée.",
    },
    {
      title: "Innovation",
      content:
        "nous nous efforçons constamment d'innover et d'améliorer notre plateforme, en tirant parti des dernières technologies pour améliorer l'expérience de location, d'achat et de vente de voitures.",
    },
    {
      title: "Assistance",
      content:
        "Notre équipe chez Motuka est un groupe dévoué de professionnels passionnés par la révolution de l’industrie automobile. Forts de notre expertise en technologie, en service client et en connaissances automobiles, nous travaillons ensemble pour garantir à nos utilisateurs la meilleure expérience possible sur notre plateforme.",
    },
  ];
  return (
    <main>
      <Helmet>
        <title>A propos</title>
        <meta
          name="description"
          content="Motuka est une application mobile qui vise à révolutionner le processus
        de location, d'achat et de vente de voitures d'occasion."
        />
      </Helmet>
      <Breadcrumb title={"A propos de nous"} />
      <Container sx={{ p: 3 }}>
        <Typography textAlign={"center"}>
          Motuka est une application mobile qui vise à révolutionner le
          processus de location, d'achat et de vente de voitures d'occasion.
          Notre plateforme conviviale offre une expérience transparente aux
          personnes cherchant à trouver la voiture de leurs rêves ou à gagner de
          l'argent supplémentaire en louant ou en vendant leur véhicule.
        </Typography>
      </Container>
      <Grid sx={{ p: 5 }} direction={"row"} container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h3">Notre Mission</Typography>
          <Typography sx={{ my: 1 }}>
            Chez Motuka, notre mission est de simplifier le processus de
            location, d'achat et de vente de voitures, en le rendant accessible
            et pratique pour tous. Nous nous efforçons de connecter les
            propriétaires de voitures et les acheteurs ou locataires potentiels,
            garantissant une expérience sans tracas et favorisant la confiance
            au sein de la communauté automobile.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography textAlign={"center"}>
            <img src="/data-synchronization.png" width={200} />
          </Typography>
        </Grid>
      </Grid>
      <Grid sx={{ p: 5 }} direction={"row-reverse"} container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h3">Notre Vision</Typography>
          <Typography sx={{ my: 1 }}>
            Notre vision est de devenir l'application mobile incontournable pour
            les particuliers à la recherche de voitures d'occasion abordables et
            fiables. Notre objectif est de créer un marché de confiance où les
            propriétaires de voitures peuvent facilement se connecter avec des
            acheteurs ou des locataires intéressés, tout en fournissant
            également une plate-forme offrant un large éventail d'options à ceux
            qui recherchent le véhicule idéal.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography textAlign={"center"}>
            <img src="/steering-wheel.png" width={200} />
          </Typography>
        </Grid>
      </Grid>
      <Grid sx={{ p: 5 }} direction={"row"} container spacing={2}>
        {features.map((item) => (
          <Fragment>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  p: 3,
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 8,
                  height: { md: 280, xs: "auto" },
                }}
              >
                <Typography fontWeight={"600"} color={"secondary"} variant="h5">
                  {item.title}{" "}
                </Typography>
                <Typography>{item.content} </Typography>
              </Box>
            </Grid>
          </Fragment>
        ))}
        <Grid item xs={12} md={6}></Grid>
      </Grid>
    </main>
  );
};

export default About;
