import {
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { doc, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import BookingModal from "../../components/product/BookingModal";
import Contact from "../../components/includes/Contact";
import { Helmet } from "react-helmet";

const RentaDetails = () => {
  const params = useParams();
  const [car, setCar] = useState({});
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const docRef = doc(db, "cars", params && params.carID);

    const unsubscribe = onSnapshot(
      docRef,
      (snapshot) => {
        if (snapshot.data()) {
          setCar({ ...snapshot.data(), id: snapshot.id });
        }
      },
      (err) => {
        console.log(err.message);
      }
    );
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Box>
      <Helmet>
        <title>{car.name}</title>
        <meta
          name="description"
          content="Location journalière avec chauffeur"
        />
        <meta property="og:title" content={car.name} />
        <meta
          property="og:description"
          content={"Location journalière avec chauffeur"}
        />
        <meta
          property="og:url"
          content={
            "https://motukapp.com/location-avec-chauffeur/" + params.carID
          }
        />
        <meta property="og:image" content={car.image} />
        <meta property="product:brand" content="Motuka" />
        <meta property="product:availability" content="in stock" />
        <meta property="product:condition" content={car.etat} />
        <meta property="product:price:amount" content={car.price} />
        <meta property="product:price:currency" content="USD" />
        <meta property="product:retailer_item_id" content={params.carID} />
        <meta name="fb:content_ids" content={params.carID} />
      </Helmet>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography textAlign={"center"}>
              <img src={car && car.image} width={"100%"} alt={car.name} />
            </Typography>
          </Grid>
          <Grid
            container
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            item
            xs={12}
            md={6}
          >
            <Box sx={{ p: 3 }}>
              <Container>
                <Typography variant="h3">{car.name}</Typography>
                <Typography
                  textAlign={"center"}
                  sx={{
                    mt: 2,
                    backgroundColor: theme.palette.primary.main,
                    color: "black",
                    p: 0.5,
                    width: "40%",
                    borderRadius: 1,
                    fontSize: 15,
                  }}
                >
                  {car.price} USD/jour
                </Typography>
                <Box>
                  <Grid sx={{ my: 1 }} container spacing={1}>
                    <Grid item xs={12} md={4}>
                      <Box
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          borderRadius: 8,
                          p: 3,
                          height: 70,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography>Offre journalière de 8h à 21h</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Box
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          borderRadius: 8,
                          p: 3,
                          height: 70,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography>Chauffeur inclu</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Box
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          borderRadius: 8,
                          p: 3,
                          height: 70,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography>Carburant à la charge du client</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Typography sx={{ my: 2 }}>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="La location journalière commence de 8h à 21h " />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="La location est uniquement avec Chauffeur" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Le carburant est à la charge du client" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Le prix de la location est fixé pour une circulation dans la ville, en dehors de la ville le tarif compte double pour la location journalière" />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Une penalité de 20$/h est appliquée pour les heures
                supplémentaires."
                      />
                    </ListItem>
                  </List>
                </Typography>
                <Typography textAlign={"center"} sx={{ mb: 4 }}>
                  <BookingModal car={car} open={open} setOpen={setOpen} />
                </Typography>
              </Container>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Contact />
    </Box>
  );
};

export default RentaDetails;
