import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import React from "react";

const OfferBanner = () => {
  const theme = useTheme();
  return (
    <Box sx={{ p: 5 }}>
      <Grid
        sx={{ backgroundColor: theme.palette.primary.main, p: 5 }}
        container
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid item xs={12}>
          <Box>
            <Typography color={"secondary"} textAlign={"center"} variant="h3">
              Vous vendez votre voiture?
            </Typography>
            <Typography textAlign={"center"}>
              Soumettez votre offre sur la marketplace MOTUKA et nous trouvons
              des acheteurs serieux pour vous.
            </Typography>
            <Typography sx={{ my: 3 }} textAlign={"center"}>
              <Button
                variant="contained"
                disableElevation
                component="a"
                href="/vendre-ma-voiture"
                sx={{
                  color: theme.palette.primary.main,
                  backgroundColor: "black",
                  borderColor: "black",
                  borderRadius: 10,
                  px: 4,
                }}
              >
                Vendre ma voiture
              </Button>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OfferBanner;
