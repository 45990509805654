import React from "react";
import { Grid } from "@mui/material";
import { Paper } from "@mui/material";
import LoginForm from "../../components/auth/Form";
import { Helmet } from "react-helmet";

function Login() {
  return (
    <main>
      <Helmet>
        <title>Connectez-vous à MOTUKA</title>
        <meta
          name="description"
          content="Connectez-vous à votre compte MOTUKA, et gérez vos offres et réservations"
        />
      </Helmet>
      <Grid container sx={{ backgroundColor: "#fff" }} spacing={2}>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          item
          sx={{ height: window.innerHeight }}
          xs={12}
        >
          <Paper
            elevation={0}
            sx={{
              p: 5,
              width: 500,
            }}
          >
            <LoginForm />
          </Paper>
        </Grid>
      </Grid>
    </main>
  );
}

export default Login;
