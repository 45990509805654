import { Avatar, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { doc, onSnapshot } from "firebase/firestore";

const Infos = () => {
  const currentUser = localStorage.getItem("userID");
  const [user, setUser] = useState({});

  useEffect(() => {
    const docRef = doc(db, "users", currentUser);
    const unsubscribe = onSnapshot(
      docRef,
      (snapshot) => {
        if (snapshot.data()) {
          setUser({ ...snapshot.data(), id: snapshot.id });
        }
      },
      (err) => {
        console.log(err.message);
      }
    );
    return () => unsubscribe();
  }, [currentUser]);

  return (
    <Container sx={{ p: 5 }}>
      <Grid direction={"row"} justifyContent={"center"} container spacing={2}>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          item
          xs={12}
          md={4}
        >
          <Avatar
            src={user && user.photoURL}
            sx={{ width: 100, height: 100 }}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="h6">{user && user.displayName}</Typography>
          <Typography color={"text.secondary"} variant="subtitle1">
            {user && user.email}
          </Typography>

          <Typography variant="p" color={"text.primary"}>
            {user && user.phoneNumber}
          </Typography>

          <Typography color={"text.secondary"}>{user && user.about}</Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Infos;
