import About from "./views/about";
import Mentions from "./views/about/Mentions";
import Policy from "./views/about/Policy";
import Login from "./views/auth";
import Register from "./views/auth/Register";
import Home from "./views/home";
import Offer from "./views/offer";
import Profile from "./views/profile";
import CarsRental from "./views/rental";
import RentaDetails from "./views/rental/Details";
import SaleCars from "./views/sale";
import SaleDetails from "./views/sale/Details";

const routes = [
  {
    name: "Home",
    path: "/",
    component: <Home />,
  },
  {
    name: "Login",
    path: "/se-connecter",
    component: <Login />,
  },
  {
    path: "/creer-un-compte",
    component: <Register />,
  },
  {
    path: "/profil",
    component: <Profile />,
  },
  {
    name: "Marketplace",
    path: "/marketplace",
    component: <SaleCars />,
  },
  {
    name: "Sale Details",
    path: "/marketplace/:productID",
    component: <SaleDetails />,
  },
  {
    name: "Rental",
    path: "/location-avec-chauffeur",
    component: <CarsRental />,
  },
  {
    name: "Rental",
    path: "/location-avec-chauffeur/:carID",
    component: <RentaDetails />,
  },
  {
    name: "Offer",
    path: "/vendre-ma-voiture",
    component: <Offer />,
  },
  {
    name: "Mentions",
    path: "/mentions-legales",
    component: <Mentions />,
  },
  {
    name: "Politique",
    path: "/politique-de-confidentialite",
    component: <Policy />,
  },
  {
    name: "About",
    path: "/a-propos",
    component: <About />,
  },
];
export default routes;
