import React from "react";
import { Grid } from "@mui/material";
import { Paper } from "@mui/material";
import LoginForm from "../../components/auth/Form";
import { Navigate } from "react-router-dom";
import { auth } from "../../firebase";
import { useSelector } from "react-redux";
import RegisterForm from "../../components/auth/RegisterForm";
import { Helmet } from "react-helmet";

function Register() {
  const user = localStorage.getItem("userID");

  if (!user)
    return (
      <main>
        <Helmet>
          <title>Créez votre compte MOTUKA</title>
          <meta
            name="description"
            content="Créez votre compte MOTUKA pour bénéficier de plus de fonctionnalités"
          />
        </Helmet>
        <Grid container sx={{ backgroundColor: "#fff" }} spacing={2}>
          <Grid
            container
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            item
            sx={{ height: window.innerHeight }}
            xs={12}
          >
            <Paper
              elevation={0}
              sx={{
                p: 5,
                width: 500,
              }}
            >
              <RegisterForm />
            </Paper>
          </Grid>
        </Grid>
      </main>
    );
  return <Navigate to={"/profil"} replace={true} />;
}

export default Register;
