export const menuList = [
  {
    name: "Marketplace",
    href: "/marketplace",
  },
  {
    name: "Location avec chauffeur",
    href: "/location-avec-chauffeur",
  },
  {
    name: "Vendre ma voiture",
    href: "/vendre-ma-voiture",
  },
];
