import { Box, Container } from "@mui/material";
import React from "react";
import Breadcrumb from "../../components/includes/Breadcrumb";

const Policy = () => {
  return (
    <Box>
      <Breadcrumb title={"Politique de confidentialité"} />
      <Container>
        <p>Dernière mise à jour : 14 février 2024</p>
        <p>
          Cette politique de confidentialité décrit nos politiques et procédures
          relatives à la collecte, à l'utilisation et à la divulgation de vos
          informations lorsque vous utilisez le service et vous informe de vos
          droits en matière de confidentialité et de la manière dont la loi vous
          protège.
        </p>
        <p>
          Nous utilisons vos données personnelles pour fournir et améliorer le
          service. En utilisant le Service, vous acceptez la collecte et
          l'utilisation d'informations conformément à la présente politique de
          confidentialité.
        </p>
        <h2>Interprétation et définitions</h2>
        <h3>Interprétation</h3>
        <p>
          Les mots dont la lettre initiale est en majuscule ont des
          significations définies dans les conditions suivantes. Les définitions
          suivantes auront la même signification qu'elles apparaissent au
          singulier ou au pluriel.
        </p>
        <h3>Définitions</h3>
        <p>Pour les besoins de cette politique de confidentialité :</p>
        <ul>
          <li>
            <p>
              <strong>Compte</strong> désigne un compte unique créé pour vous
              permettre d'accéder à notre service ou à des parties de notre
              service.
            </p>
          </li>
          <li>
            <p>
              <strong>Affilié</strong> désigne une entité qui contrôle, est
              contrôlée par ou est sous contrôle commun avec une partie, où le
              « contrôle »  désigne la propriété de 50 % ou plus des actions,
              participations ou autres titres donnant droit à voter pour
              l'élection des administrateurs ou de toute autre autorité de
              gestion.
            </p>
          </li>
          <li>
            <p>
              <strong>Application</strong> fait référence à Motuka, le logiciel
              fourni par la Société.
            </p>
          </li>
          <li>
            <p>
              <strong>Société</strong> (désignée par « la Société », « Nous », «
              Notre » ou « Notre » dans le présent Accord) fait référence à
              Hashen, 44, avenue de la démocratie. , Kinshasa - Gombe.
            </p>
          </li>
          <li>
            <p>
              <strong>Pays</strong> fait référence à : République Démocratique
              du Congo
            </p>
          </li>
          <li>
            <p>
              <strong>Appareil</strong> désigne tout appareil pouvant accéder au
              Service, tel qu'un ordinateur, un téléphone portable ou une
              tablette numérique.
            </p>
          </li>
          <li>
            <p>
              <strong>Les données personnelles</strong> désignent toute
              information relative à une personne identifiée ou identifiable.
            </p>
          </li>
          <li>
            <p>
              <strong>Service</strong> fait référence à l'Application.
            </p>
          </li>
          <li>
            <p>
              <strong>Prestataire de services</strong> désigne toute personne
              physique ou morale qui traite les données pour le compte de la
              Société. Il fait référence à des sociétés tierces ou à des
              personnes employées par la Société pour faciliter le Service, pour
              fournir le Service au nom de la Société, pour exécuter des
              services liés au Service ou pour aider la Société à analyser la
              manière dont le Service est utilisé.
            </p>
          </li>
          <li>
            <p>
              <strong>Données d'utilisation</strong> font référence aux données
              collectées automatiquement, soit générées par l'utilisation du
              Service, soit à partir de l'infrastructure du Service elle-même
              (par exemple, la durée d'une visite d'une page).
            </p>
          </li>
          <li>
            <p>
              <strong>Vous</strong> désigne la personne qui accède ou utilise le
              Service, ou la société ou toute autre entité juridique au nom de
              laquelle cette personne accède ou utilise le Service, selon le
              cas.
            </p>
          </li>
        </ul>
        <h2>Collecte et utilisation de vos données personnelles</h2>
        <h3>Types de données collectées</h3>
        <h4>Données personnelles</h4>
        <p>
          Lors de l'utilisation de notre service, nous pouvons vous demander de
          nous fournir certaines informations personnelles identifiables qui
          peuvent être utilisées pour vous contacter ou vous identifier. Les
          informations personnelles identifiables peuvent inclure, sans s'y
          limiter :
        </p>
        <ul>
          <li>
            <p>Adresse e-mail</p>
          </li>
          <li>
            <p>Prénom et nom</p>
          </li>
          <li>
            <p>Numéro de téléphone</p>
          </li>
          <li>
            <p>Données d'utilisation</p>
          </li>
        </ul>
        <h4>Données d'utilisation</h4>
        <p>
          Les données d'utilisation sont collectées automatiquement lors de
          l'utilisation du service.
        </p>
        <p>
          Les données d'utilisation peuvent inclure des informations telles que
          l'adresse de protocole Internet de votre appareil (par exemple,
          l'adresse IP), le type de navigateur, la version du navigateur, les
          pages de notre service que vous visitez, l'heure et la date de votre
          visite, le temps passé sur ces pages. , identifiants uniques de
          l'appareil et autres données de diagnostic.
        </p>
        <p>
          Lorsque vous accédez au Service via ou via un appareil mobile, nous
          pouvons collecter automatiquement certaines informations, notamment,
          mais sans s'y limiter, le type d'appareil mobile que vous utilisez,
          l'identifiant unique de votre appareil mobile, l'adresse IP de votre
          appareil mobile. , Votre système d'exploitation mobile, le type de
          navigateur Internet mobile que vous utilisez, les identifiants uniques
          de l'appareil et d'autres données de diagnostic.
        </p>
        <p>
          Nous pouvons également collecter des informations que votre navigateur
          envoie chaque fois que vous visitez notre service ou lorsque vous
          accédez au service via ou via un appareil mobile.
        </p>
        <h4>Informations collectées lors de l'utilisation de l'application</h4>
        <p>
          Lors de l'utilisation de notre application, afin de fournir des
          fonctionnalités de notre application, nous pouvons collecter, avec
          votre autorisation préalable :
        </p>
        <ul>
          <li>
            <p>Informations concernant votre emplacement</p>
          </li>
          <li>
            <p>
              Images et autres informations provenant de l'appareil photo et de
              la photothèque de votre appareil
            </p>
          </li>
        </ul>
        <p>
          Nous utilisons ces informations pour fournir des fonctionnalités de
          notre service, pour améliorer et personnaliser notre service. Les
          informations peuvent être téléchargées sur les serveurs de la Société
          et/ou sur le serveur d'un fournisseur de services ou elles peuvent
          être simplement stockées sur votre appareil.
        </p>
      </Container>
    </Box>
  );
};

export default Policy;
