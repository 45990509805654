import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import CallIcon from "@mui/icons-material/Call";

const Contact = () => {
  return (
    <Grid
      sx={{ p: 6, backgroundColor: "whitesmoke" }}
      direction={"row"}
      container
      spacing={2}
    >
      <Grid item xs={12} md={6}>
        <Typography textAlign={"center"}>
          <img src="/support.png" width={200} alt="customer service" />
        </Typography>
      </Grid>
      <Grid
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        container
        item
        xs={12}
        md={6}
      >
        <Box>
          <Typography variant="h3">Service client</Typography>
          <Typography sx={{ my: 1 }}>
            Notre équipe d'experts est là pour répondre à toutes vos questions
            et vous accompagner tout au long du processus de réservation,
            d'achat ou de vente. <br />
            Nous sommes déterminés à rendre votre expérience avec MOTUKA
            agréable et sans tracas.
          </Typography>
          <Typography sx={{ my: 2 }}>
            <Button
              startIcon={<CallIcon />}
              sx={{ borderRadius: 10, color: "black" }}
              variant="contained"
              disableElevation
              component="a"
              href="tel:+243832415593"
            >
              Contactez-nous
            </Button>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Contact;
