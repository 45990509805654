import {
  Avatar,
  Badge,
  Button,
  Container,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { auth } from "../../firebase";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { editProfil } from "../../redux/actions/userActions";
import { countries } from "../../data";
import CameraAlt from "@mui/icons-material/CameraAlt";

const mapState = ({ user }) => ({
  loading: user.loading,
});

const Edit = () => {
  const currentUser = auth.currentUser;
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();
  const { loading } = useSelector(mapState);
  const theme = useTheme();

  const handleChange = (e) =>
    setUser({ ...user, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(editProfil(user));
  };

  return (
    <Container sx={{ p: 5 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid
            container
            direction={"row"}
            justifyContent={"center"}
            item
            xs={12}
          >
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <IconButton
                  sx={{ backgroundColor: theme.palette.primary.main }}
                >
                  <CameraAlt />
                </IconButton>
              }
            >
              <Avatar
                sx={{ width: 100, height: 100 }}
                component={"p"}
                src={currentUser && currentUser.photoURL}
              />
            </Badge>
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction={"row"}
            justifyContent={"center"}
          >
            <TextField
              name="displayName"
              label="Nom complet"
              fullWidth
              type="text"
              variant="standard"
              placeholder={currentUser && currentUser.displayName}
              sx={{ width: { md: "50%", xs: "100%" } }}
              onChange={handleChange}
            />
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction={"row"}
            justifyContent={"center"}
          >
            <TextField
              variant="standard"
              name="phoneCode"
              label="Code Pays"
              fullWidth
              select
              sx={{ width: { md: "50%", xs: "100%" } }}
              onChange={handleChange}
            >
              {countries.map((country) => (
                <MenuItem value={country.code}>
                  {country.country + ": +" + country.code}
                </MenuItem>
              ))}
            </TextField>

            <Grid
              container
              direction={"row"}
              justifyContent={"center"}
              item
              xs={12}
            >
              <TextField
                name="phoneNumber"
                label="Numero de téléphone"
                placeholder={"Ex: 901234567"}
                fullWidth
                variant="standard"
                sx={{ width: { md: "50%", xs: "100%" } }}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction={"row"}
            justifyContent={"center"}
          ></Grid>

          <Grid item xs={12}>
            <Typography textAlign={"center"}>
              <LoadingButton
                disableElevation
                type="submit"
                sx={{
                  width: { md: "50%", xs: "100%" },
                  mt: 4,
                  borderRadius: 10,
                }}
                fullWidth
                variant="contained"
                loading={loading}
              >
                Valider
              </LoadingButton>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default Edit;
