import React from "react";
import HomeBanner from "../../components/home/Banner";
import { Box, Grid, Typography } from "@mui/material";
import OfferBanner from "../../components/includes/OfferBanner";
import Contact from "../../components/includes/Contact";

const Home = () => {
  return (
    <main>
      <HomeBanner />
      <Grid sx={{ p: 6 }} direction={"row-reverse"} container spacing={2}>
        <Grid
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          container
          item
          xs={12}
          md={6}
        >
          <Box>
            <Typography variant="h3">
              Une large gamme de voitures d'occasion
            </Typography>
            <Typography sx={{ my: 1 }}>
              Avec une multitude d'annonces mises à jour régulièrement, vous
              avez accès à une large gamme de voitures d'occasion. Que vous
              cherchiez une voiture récente ou un modèle plus ancien, vous
              trouverez forcément votre bonheur sur MOTUKA.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography textAlign={"center"}>
            <img src="/marketplace.png" width={200} alt="Marketplace" />
          </Typography>
        </Grid>
      </Grid>
      <Grid sx={{ p: 6 }} direction={"row"} container spacing={2}>
        <Grid
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          container
          item
          xs={12}
          md={6}
        >
          <Box>
            <Typography variant="h3">
              Des voitures vérifiées et approuvées
            </Typography>
            <Typography sx={{ my: 1 }}>
              Toutes les annonces sur MOTUKA sont vérifiées et approuvées avant
              d'être publiées, garantissant ainsi la fiabilité des informations
              fournies. De plus, nous vous mettons en contacte direct avec le
              vendeur pour obtenir des détails supplémentaires ou organiser un
              essai routier.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography textAlign={"center"}>
            <img src="/marketdetails.png" width={200} alt="Market details" />
          </Typography>
        </Grid>
      </Grid>
      <OfferBanner />
      <Contact />
    </main>
  );
};

export default Home;
