import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { doc, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import Slider from "react-slick";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import OfferBanner from "../../components/includes/OfferBanner";
import Contact from "../../components/includes/Contact";
import { Helmet } from "react-helmet";
import Apple from "@mui/icons-material/Apple";
import Google from "@mui/icons-material/Google";

const SaleDetails = () => {
  const params = useParams();
  const [product, setProduct] = useState({
    images: [],
  });
  const theme = useTheme();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    const docRef = doc(db, "products", params && params.productID);

    const unsubscribe = onSnapshot(
      docRef,
      (snapshot) => {
        if (snapshot.data()) {
          setProduct({ ...snapshot.data(), id: snapshot.id });
        }
      },
      (err) => {
        console.log(err.message);
      }
    );
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Box>
      <Helmet>
        <title>{product.name}</title>
        <meta name="description" content={product.description} />
        <meta property="og:title" content={product.name} />
        <meta property="og:description" content={product.description} />
        <meta
          property="og:url"
          content={"https://motukapp.com/marketplace/" + params.productID}
        />
        <meta property="og:image" content={product.images[0]} />
        <meta property="product:brand" content="Motuka" />
        <meta property="product:availability" content="in stock" />
        <meta property="product:condition" content={product.etat} />
        <meta property="product:price:amount" content={product.price} />
        <meta property="product:price:currency" content="USD" />
        <meta property="product:retailer_item_id" content={params.productID} />
        <meta name="fb:content_ids" content={params.productID} />
      </Helmet>
      <Container>
        <Grid sx={{ mt: 10 }} container spacing={2}>
          <Grid item sx={{ p: 5 }} xs={12} md={6}>
            <Slider {...settings}>
              {product &&
                product.images.map((image) => (
                  <Box
                    sx={{
                      height: 500,
                      px: { md: 10, xs: 6 },
                      pt: { xs: 6, md: false },
                      overflow: "hidden",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundImage: `url(${image}) `,
                    }}
                  ></Box>
                ))}
            </Slider>
          </Grid>
          <Grid
            container
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            item
            xs={12}
            md={6}
          >
            <Box sx={{ p: 3 }}>
              <Container>
                <Typography variant="h3">{product.name}</Typography>
                <Typography fontSize={20} color={"secondary"}>
                  {product.etat}
                </Typography>
                <Typography
                  textAlign={"center"}
                  sx={{
                    mt: 2,
                    backgroundColor: theme.palette.primary.main,
                    color: "black",
                    p: 0.5,
                    width: "40%",
                    borderRadius: 1,
                    fontSize: 15,
                  }}
                >
                  {product.price} USD
                </Typography>

                <Typography sx={{ my: 2 }}>{product.description}</Typography>

                <Typography>
                  Téléchargez l'application et entamez la négociation pour cette
                  offre
                </Typography>

                <Typography textAlign={"center"} sx={{ mb: 4 }}>
                  <Button
                    startIcon={<Apple />}
                    disableElevation
                    variant="contained"
                    sx={{
                      backgroundColor: "black",
                      color: theme.palette.primary.main,
                      borderRadius: 10,
                      px: 4,
                      width: "48%",
                    }}
                    href="https://apple.co/3qctErs"
                    component="a"
                    target="_blank"
                  >
                    pour iOS
                  </Button>
                  <Button
                    startIcon={<Google />}
                    href="https://bit.ly/3ourAe5"
                    component="a"
                    target="_blank"
                    disableElevation
                    variant="contained"
                    sx={{
                      borderRadius: 10,
                      px: 4,
                      width: "48%",
                      ml: 1,
                    }}
                  >
                    pour Android
                  </Button>
                </Typography>
              </Container>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <OfferBanner />
      <Contact />
    </Box>
  );
};

export default SaleDetails;
