import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { Grid, useTheme } from "@mui/material";
import SearchBar from "../../components/includes/SearchBar";
import Cars from "../../components/product/Cars";

const Favourites = () => {
  const theme = useTheme();
  const [cars, setCars] = useState([]);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const currentUser = localStorage.getItem("userID");

  useEffect(() => {
    const colRef = collection(db, "products");
    const q = query(
      colRef,
      where("usersWhoFavorited", "array-contains", currentUser),
      orderBy("createdAt", "desc")
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      let content = [];
      if (snapshot.docs) {
        snapshot.docs.forEach((doc) =>
          content.push({ id: doc.id, ...doc.data() })
        );
        setCars(content);
        setData(content);
      }
    });

    // Unsubscribe from events when no longer in use
    return () => unsubscribe();
  }, [cars]);

  const searchFilter = (text) => {
    const newData =
      cars &&
      cars.filter((item) => {
        const itemData = item.description
          ? item.description.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
    setSearch(text);
    setData(newData);
  };

  return (
    <div>
      <Grid container sx={{ p: 8 }} spacing={2}>
        {data && data.map((car) => <Cars data={car} />)}
      </Grid>
    </div>
  );
};

export default Favourites;
