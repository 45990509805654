import { Grid, Pagination, Typography, useTheme } from "@mui/material";
import React from "react";
import SearchBar from "../../components/includes/SearchBar";
import { useState } from "react";
import { useEffect } from "react";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import Cars from "../../components/product/Cars";
import RentalCars from "../../components/product/RentalCars";
import Banner from "../../components/includes/Banner";
import Contact from "../../components/includes/Contact";
import { Helmet } from "react-helmet";

const CarsRental = () => {
  const theme = useTheme();
  const [cars, setCars] = useState([]);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const itemsPerPage = 12;
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = search ? data : data.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(data.length / itemsPerPage);

  useEffect(() => {
    const colRef = collection(db, "cars");
    const q = query(
      colRef,
      where("inStock", "==", true),
      orderBy("price", "asc")
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      let content = [];
      if (snapshot.docs) {
        snapshot.docs.forEach((doc) =>
          content.push({ id: doc.id, ...doc.data() })
        );
        setCars(content);
        setData(content);
      }
    });

    // Unsubscribe from events when no longer in use
    return () => unsubscribe();
  }, []);

  const searchFilter = (text) => {
    const newData =
      cars &&
      cars.filter((item) => {
        const itemData = item.name ? item.name.toUpperCase() : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
    setSearch(text);
    setData(newData);
    setItemOffset(0);
  };

  const handlePageClick = (event, value) => {
    const newOffset = (value * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  return (
    <main>
      <Helmet>
        <title>Réservez un MOTUKA pour vos courses et séjours</title>
        <meta
          name="description"
          content="Réservez nos voitures pour vos courses, séjours, et événements."
        />
      </Helmet>
      <Banner
        title={"Réservez un MOTUKA pour vos courses et séjours"}
        subtitle={
          "Réservez nos voitures pour vos courses, séjours, et événements"
        }
        image={"/rentalBan.png"}
      />
      <Grid
        sx={{ p: 8, backgroundColor: theme.palette.primary.main }}
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <Typography variant="h3" color={"text.primary"} textAlign={"center"}>
            Catalogue de location de voitures
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ p: 8, backgroundColor: "#eee" }} spacing={2}>
        <Grid item xs={12}>
          <SearchBar
            searchFilter={searchFilter}
            placeholder={"Ex: TOYOTA IST, Suzuki Swift..."}
          />
        </Grid>
        {currentItems && currentItems.map((car) => <RentalCars data={car} />)}
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          sx={{ mt: 2 }}
          item
          xs={12}
        >
          <Pagination
            variant="outlined"
            color="primary"
            onChange={handlePageClick}
            count={pageCount}
          />
        </Grid>
      </Grid>
      <Contact />
    </main>
  );
};

export default CarsRental;
