import { doc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../firebase";

export const editProfil = (data) => {
  return (dispatch) => {
    const currentUser = auth.currentUser.uid;
    const docRef = doc(db, "users", currentUser);

    dispatch({ type: "EDIT_PROFIL_LOADING", payload: true });

    if (data.phoneNumber) {
      updateDoc(docRef, {
        ...data,
        phoneNumber: "+" + data.phoneCode + parseInt(data.phoneNumber),
      })
        .then(() => {
          dispatch({ type: "EDIT_PROFIL_SUCCESS" });
        })
        .catch((err) => {
          dispatch({ type: "EDIT_PROFIL_ERROR", payload: err.message });
        });
    } else {
      updateDoc(docRef, {
        ...data,
      })
        .then(() => {
          dispatch({ type: "EDIT_PROFIL_SUCCESS" });
        })
        .catch((err) => {
          dispatch({ type: "EDIT_PROFIL_ERROR", payload: err.message });
        });
    }
  };
};
