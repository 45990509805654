import {
  signInWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  OAuthProvider,
} from "firebase/auth";
import { auth, db } from "../../firebase";
import { collection, doc, setDoc } from "firebase/firestore";

export const login = (credentials, redirect) => {
  return (dispatch) => {
    dispatch({ type: "LOGIN_LOADING", payload: true });

    signInWithEmailAndPassword(auth, credentials.email, credentials.password)
      .then((result) => {
        localStorage.setItem("userID", result.user.uid);

        dispatch({ type: "LOGIN_SUCCESS" });
        return redirect();
      })
      .catch((err) => {
        dispatch({ type: "LOGIN_ERROR", payload: err.message });
      });
  };
};

export const register = (credentials, redirect) => {
  return (dispatch) => {
    const colRef = collection(db, "users");
    dispatch({ type: "REGISTER_LOADING", payload: true });

    createUserWithEmailAndPassword(
      auth,
      credentials.email,
      credentials.password
    )
      .then((result) => {
        localStorage.setItem("userID", result.user.uid);
        setDoc(
          doc(colRef, result.user.uid),
          {
            email: result.user.email,
          },
          { merge: true }
        );

        dispatch({ type: "REGISTER_SUCCESS" });
        return redirect();
      })
      .catch((err) => {
        dispatch({ type: "REGISTER_ERROR", payload: err.message });
      });
  };
};

export const logOut = (redirect) => {
  return (dispatch) => {
    signOut(auth)
      .then(() => {
        localStorage.clear();
        dispatch({ type: "LOGOUT_SUCCESS" });
        return redirect();
      })
      .catch((err) => {
        dispatch({ type: "LOGOUT_ERROR", err });
      });
  };
};

export const loginWithGoogle = (redirect) => {
  return (dispatch) => {
    const googleProvider = new GoogleAuthProvider();
    const colRef = collection(db, "users");

    dispatch({ type: "GOOGLE_LOGIN_LOADING", payload: true });
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        setDoc(
          doc(colRef, result.user.uid),
          {
            email: result.user.email,
            photoURL: result.user.photoURL,
            displayName: result.user.displayName,
          },
          { merge: true }
        );
        localStorage.setItem("userID", result.user.uid);
        dispatch({ type: "GOOGLE_LOGIN_SUCCESS" });
        return redirect();
      })
      .catch((err) => {
        dispatch({ type: "GOOGLE_LOGIN_ERROR", payload: err.message });
      });
  };
};

export const loginWithApple = (redirect) => {
  return (dispatch) => {
    const provider = new OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");
    provider.setCustomParameters({
      // Localize the Apple authentication screen in French.
      locale: "fr",
    });

    const colRef = collection(db, "users");

    dispatch({ type: "APPLE_LOGIN_LOADING", payload: true });
    signInWithPopup(auth, provider)
      .then((result) => {
        setDoc(
          doc(colRef, result.user.uid),
          {
            email: result.user.email,
            photoURL: result.user.photoURL,
            displayName: result.user.displayName,
          },
          { merge: true }
        );
        localStorage.setItem("userID", result.user.uid);
        dispatch({ type: "APPLE_LOGIN_SUCCESS" });
        return redirect();
      })
      .catch((err) => {
        dispatch({ type: "APPLE_LOGIN_ERROR", payload: err.message });
      });
  };
};
