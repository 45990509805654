import { Box, Button, Grid, Typography, alpha, useTheme } from "@mui/material";
import React from "react";
import AppleIcon from "@mui/icons-material/Apple";
import GoogleIcon from "@mui/icons-material/Google";

const Banner = ({ title, subtitle, image }) => {
  const theme = useTheme();
  return (
    <Grid
      container
      sx={{
        overflow: "hidden",
        backgroundSize: "cover",
        backgroundColor: "whitesmoke",
        p: 6,
      }}
      spacing={2}
    >
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        item
        xs={12}
        spacing={2}
        md={6}
      >
        <Box sx={{ p: 3 }}>
          <Typography textAlign={"center"} variant="h3">
            {title}
          </Typography>
          <Typography sx={{ px: 5 }} textAlign={"center"}>
            {subtitle}
          </Typography>
          <Typography textAlign={"center"} sx={{ my: 3 }}>
            <Button
              startIcon={<AppleIcon />}
              sx={{
                backgroundColor: "black",
                color: theme.palette.primary.main,
                borderColor: "black",
                m: 1,
                borderRadius: 10,
              }}
              variant="outlined"
              href="https://apps.apple.com/app/id6449201158"
              component="a"
              target="_blank"
            >
              Télécharger pour iOS
            </Button>
            <Button
              startIcon={<GoogleIcon />}
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: "black",
                borderColor: theme.palette.primary.main,
                borderRadius: 10,
                m: 1,
              }}
              variant="outlined"
              href="https://play.google.com/store/apps/details?id=com.motukapp&pli=1"
              component="a"
              target="_blank"
            >
              Télécharger pour Android
            </Button>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography sx={{ mb: -1 }} textAlign={"center"}>
          <img src={image} width={300} alt="banner image" />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Banner;
