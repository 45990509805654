import { addDoc, collection, deleteDoc, doc } from "firebase/firestore";
import { auth, db } from "../../firebase";

export const makeBooking = (booking, user) => {
  return (dispatch) => {
    const currentUser = auth.currentUser.uid;
    const colRef = collection(db, "bookings");

    dispatch({ type: "MAKE_BOOKING_LOADING", payload: true });

    addDoc(colRef, {
      ...booking,
      ...user,
      createdAt: new Date().getTime(),
      user: currentUser,
    })
      .then(() => dispatch({ type: "MAKE_BOOKING_SUCCESS" }))
      .catch((err) => dispatch({ type: "MAKE_BOOKING_ERROR" }));
  };
};

export const cancelBooking = (booking) => {
  return (dispatch) => {
    const docRef = doc(db, "bookings", booking);

    dispatch({ type: "CANCEL_BOOKING_LOADING", payload: true });

    deleteDoc(docRef)
      .then(() => dispatch({ type: "CANCEL_BOOKING_SUCCESS" }))
      .catch((err) => dispatch({ type: "CANCEL_BOOKING_ERROR" }));
  };
};
