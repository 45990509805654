import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Infos from "./Infos";
import { Container, Grid } from "@mui/material";
import Edit from "./Edit";
import Account from "./Account";
import { Navigate } from "react-router-dom";
import Favourites from "./Favourites";
import UserOffers from "./Offers";
import Bookings from "./Bookings";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const mapState = ({ auth }) => ({
  currentUser: auth.currentUser,
});

export default function Profile() {
  const [value, setValue] = React.useState(0);
  const user = localStorage.getItem("userID");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (user)
    return (
      <Grid container sx={{ py: 5 }} spacing={2}>
        <Grid item xs={12}>
          <Container sx={{ m: 5 }}>
            <Typography variant="h4">Profil</Typography>
          </Container>
        </Grid>
        <Grid item xs={12}>
          <Container>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  aria-label="scrollable force tabs example"
                  centered
                >
                  <Tab label="Informations" {...a11yProps(0)} />
                  <Tab label="Modifer mon profil" {...a11yProps(1)} />
                  <Tab label="Liste à souhaits" {...a11yProps(2)} />
                  <Tab label="Mes réservations" {...a11yProps(3)} />
                  <Tab label="Mes offres" {...a11yProps(4)} />
                  <Tab label="Compte" {...a11yProps(5)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Infos />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Edit />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <Favourites />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <Bookings />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={4}>
                <UserOffers />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={5}>
                <Account />
              </CustomTabPanel>
            </Box>
          </Container>
        </Grid>
      </Grid>
    );
  return <Navigate to={"/se-connecter"} replace={true} />;
}
