import {
  Button,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Banner from "../../components/includes/Banner";
import CameraAlt from "@mui/icons-material/CameraAlt";
import { Helmet } from "react-helmet";
import { db } from "../../firebase";
import { addDoc, collection, doc, onSnapshot } from "firebase/firestore";
import { LoadingButton } from "@mui/lab";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { toast } from "react-toastify";

const Offer = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [images, setImages] = useState([]);
  const [user, setUser] = useState({});
  const currentUser = localStorage.getItem("userID") || "1";

  useEffect(() => {
    const docRef = doc(db, "users", currentUser);
    const unsubscribe = onSnapshot(
      docRef,
      (snapshot) => {
        if (snapshot.data()) {
          setUser({ ...snapshot.data(), id: snapshot.id });
        }
      },
      (err) => {
        console.log(err.message);
      }
    );
    return () => unsubscribe();
  }, [currentUser]);

  const handleChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });

  const handleFileChange = (event) => {
    const files = event.target.files;
    setImages([...images, ...files]);
  };

  const uploadImages = async (files) => {
    const promises = [];
    for (const file of files) {
      const storage = getStorage();
      const imageName = (new Date().getTime() + 1).toString();
      const storageRef = ref(storage, `images/${imageName}.jpg`);
      const uploadTask = uploadBytes(storageRef, file);

      promises.push(
        uploadTask.then(() => {
          return getDownloadURL(storageRef);
        })
      );
    }

    const downloadURLs = await Promise.all(promises);
    return downloadURLs;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const downloadURLs = await uploadImages(images);
    const colRef = collection(db, "products");

    // Add document to Firestore with download URLs
    await addDoc(colRef, {
      images: downloadURLs,
      ...data,
      displayName: user.displayName,
      phoneNumber: user.phoneNumber,
      email: user.email,
      user: currentUser,
      status: "init",
      createdAt: new Date().getTime(),
      // other document fields
    })
      .then(() => {
        toast.success("Offre envoyé");
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
      });
  };

  return (
    <main>
      <Helmet>
        <title>Vendez facilement votre voiture sur MOTUKA</title>
        <meta
          name="description"
          content="Nous simplifions et révolutionnons votre expérience automobile."
        />
      </Helmet>
      <Banner
        title={"Vendez facilement votre voiture sur MOTUKA"}
        subtitle={
          "Nous simplifions et révolutionnons votre expérience automobile."
        }
        image={"/offerBan.png"}
      />
      <form onSubmit={handleSubmit}>
        <Container>
          <Grid sx={{ py: 5 }} container spacing={2}>
            <Grid item xs={12}>
              <Typography textAlign={"center"}>
                <input
                  id="import"
                  style={{ display: "none" }}
                  type="file"
                  name="importer les images"
                  accept="images/*"
                  multiple
                  onChange={handleFileChange}
                />
                <label htmlFor="import">
                  <Button
                    sx={{ borderRadius: 10 }}
                    startIcon={<CameraAlt />}
                    component="span"
                    variant="outlined"
                  >
                    Importer les images
                  </Button>
                </label>
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              {images.map((image) => (
                <img
                  key={image.name}
                  src={URL.createObjectURL(image)}
                  alt={image.name}
                  width={200}
                />
              ))}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="name"
                label="Nom du véhicule"
                placeholder="Ex: Hyundai Palissade"
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                name="price"
                label="Prix de vente"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                select
                name="etat"
                label="Etat du véhicule"
                onChange={handleChange}
              >
                <MenuItem value="used">Usé (Occasion)</MenuItem>
                <MenuItem value="new">Neuf </MenuItem>
                <MenuItem value="out-of-use">Hors-usage </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                name="description"
                label="Description"
                multiline
                rows={5}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography textAlign={"center"}>
                {currentUser != "1" ? (
                  <LoadingButton
                    type="submit"
                    loading={loading}
                    disableElevation
                    sx={{ borderRadius: 10, width: { md: "40%", xs: "100%" } }}
                    variant="contained"
                  >
                    Envoyer
                  </LoadingButton>
                ) : (
                  <Button
                    disableElevation
                    sx={{ borderRadius: 10, width: { md: "40%", xs: "100%" } }}
                    variant="contained"
                    href="/se-connecter"
                  >
                    Se connecter pour soumettre
                  </Button>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </form>
    </main>
  );
};

export default Offer;
